import useDashboard from "../../hooks/useDashboard";

const Dashboard = () => {
  const { constanciasList } = useDashboard();  
  return (
    <form 
      className="my-10 bg-white shadow rounded-lg p-10"
      >
        <h1 className="text-4xl font-black">Dashboard_V1</h1>    
    </form>
  )
}

export default Dashboard