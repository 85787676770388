import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import clientAxios from '../config/clientAxios';
import Alerts from '../components/Alerts';

const PassReset = () => {
    const [password, setPassword] = useState('');
    const [ tokenValido, setTokenValido ] = useState(false);
    const [ alert, setAlert ] = useState('');
    const [ passwordModificado, setPasswordModificado ] = useState(false);
    const params = useParams();
    const { token } = params;

    useEffect(() => {
        const comprobarToken = async () =>{
          try {
            const url = `/users/passchecktoken/${token}`;
            await clientAxios(url);
            setTokenValido(true);
          } catch (error) {
            setAlert({ msg:error.response.data.msg, error: true});
            setTokenValido(false);
          }
        };    
        comprobarToken();
      }, []);
      
      const handleSubmit = async e => {
        e.preventDefault();
        if (password.length < 7) {
            setAlert({msg: 'El password debe ser de mas de 6 caracteres.', error: true});
            return;
        }
        try {
          const url = `/users/passupdate`;
          const { data } = await clientAxios.put(url, {password, token});
          setAlert({ msg: data.msg, error: false });
          setPassword('');
          setPasswordModificado(true);
          setTokenValido(false);
        } catch (error) {
          setAlert({ msg: error.response.data.msg, error: true});
          setTokenValido(false);
        }    
      }    
      const { msg } = alert;
      return (
        <>
          <h1 className="text-sky-600 font-black text-6xl capitalize">Actualiza Password</h1>
            {msg && <Alerts alert={alert} />}        
            { tokenValido && (
              <form 
                className="my-10 bg-white shadow rounded-lg p-10"
                onSubmit={handleSubmit}
              >
              <div className="my-5">
                <label 
                  className="uppercase text-gray-600 block text-xl font-bold"
                  htmlFor="password"
                >Nuevo Password</label>
                <input 
                  id="password"
                  type="password"
                  placeholder="Nuevo Password de Acceso."
                  className="w-full mt-3 p-3 border rounded-xl bg-gray-50"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
              </div>
              <input
                type="submit"
                value="Actualizar Password"
                className="bg-sky-400 md-5 w-full py-3 text-white uppercase fond-bold rounded-xl hover:cursor-pointer hover:bg-sky-800 transition-colors"
              />
            </form>
            )}
             {passwordModificado && (
                <nav className="lg:flex lg:justify-center">
                    {msg && <Alerts alert={alert} />}  
                <Link
                  className="block text-center my-5 text-slate-500 uppercase text-sm"
                    to="/"
                  >Inicia Sesión.</Link>                   
                </nav>
             )}
        </>
      )
}

export default PassReset;