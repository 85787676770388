import FormListUsers from "../../../components/FormListUsers";

const FormUsersList = () => {
    console.log('FormListUsers ' + JSON.stringify(FormUsersList));
    return(
        <form 
        className="my-10 bg-white shadow rounded-lg p-10"
        >
             <h1 className="text-4xl font-black">Lista de usuarios</h1>
            <div className="mt-10 flex justify-center">
                <FormListUsers />
            </div>
        </form>
    )
}

export default FormUsersList;