import { useState } from 'react';
import { Link } from 'react-router-dom';
import clientAxios from '../config/clientAxios';
import Alerts from '../components/Alerts';

const PassRecovery = () => {

    const [ email, setEmail ] = useState('');
    const [ alert, setAlert ] = useState('');

    const handleSubmit = async e => {
        e.preventDefault();
        if (!email) {
            setAlert({ msg:'Email obligatorio', error: true });
            return
        }
        try {
            const { data } = await clientAxios.put(`/users/login`, {email});
            setAlert({ msg: data.msgErr, error: false });
            setEmail('');
        } catch (error) {
            setAlert({ msg:error.response.data.msgErr, error: true });
        }
    }
    const { msg } = alert;
    return (
        <>
            <h1 className="text-sky-600 font-black text-6xl capitalize">Restaurar Password</h1>
            { msg && <Alerts alert={alert} />}
            <form 
                className="my-10 bg-white shadow rounded-lg p-10"
                onSubmit={handleSubmit}
            >
            <div className="my-5">
                <label 
                    className="uppercase text-gray-600 block text-xl font-bold"
                    htmlFor="email"
                >Email</label>
                <input 
                    id="email"
                    type="email"
                    placeholder="Email de Registro."
                    className="w-full mt-3 p-3 border rounded-xl bg-gray-50"
                    value={email}
                    onChange={ e => setEmail(e.target.value)}
                />
            </div>   
            <input
                type="submit"
                value="Enviar Instrucciones"
                className="bg-sky-400 md-5 w-full py-3 text-white uppercase fond-bold rounded-xl hover:cursor-pointer hover:bg-sky-800 transition-colors"
            />
            </form>
            <nav className="lg:flex lg:justify-between">
                <Link className="block text-center my-5 text-slate-500 uppercase text-sm" to="/">Login.</Link>           
            </nav>
        </>
    )
}
export default PassRecovery