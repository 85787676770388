import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import clientAxios from '../config/clientAxios';
import Alerts from '../components/Alerts';

const ConfirmAccount = () => {
  const params = useParams();
  const { token } = params;
  const [ alert, setAlert ] = useState('');
  const [ confirmedAccount, setConfirmedAccount ] = useState(false);

  useEffect(() => {
    const confirmAccount = async () => {
      try {
        const url = `https://bgpe2d50kd.execute-api.us-east-1.amazonaws.com/api/users/confirmaccount/${token}`;
        const response = await clientAxios(url);
        setAlert({ msg:response.data.msgErr, error: false });
        setConfirmedAccount(true);
      } catch (error) {
        setAlert({ msg:error.response.data.msgErr, error: true});
      }
    };
    confirmAccount();
  }, []);
  const { msg } = alert;
  return (
    <>
      <h1 className="text-sky-600 font-black text-6xl capitalize">Confirmacio de cuenta</h1>
      <div className='mt-20 md:mt-5 shadow-lg px-5 py-10 rounded-xl bg-white'>
        {msg && <Alerts alert={alert} />}
        </div>
        <div>
          {confirmedAccount && (
            <nav className="lg:flex lg:justify-center">
              <Link
              className="block text-center my-5 text-slate-500 uppercase text-sm"
                to="/"
              >Inicia Sesión.</Link>
            </nav>
          )}          
        </div>
    </>
  )
}

export default ConfirmAccount;