import { Link } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
// import useDashboard from '../hooks/useDashboard';


const Header = () => {
    const { auth } = useAuth();
    // const { cerrarSesionDashboard } = useDashboard();
    // const { cerrarSesionAuth } = useAuth();
    // const handleCerrarSesion = () => {
    //     cerrarSesionAuth();
    //     cerrarSesionDashboard();
    //     localStorage.removeItem('token');
    // }
  return (
    <header className="px-4 py-5 bg-white border-b">
        <div className="md:flex md:justify-between">
            <h2 className="text-4xl text-sky-600 font-black text-center">
                ACEROMEX
            </h2>
            {/* <input
                type="search"
                placeholder="Buscar constancia"
                className="rounded-lg lg:w-96 block p-2 border"
            /> */}
            <p className='text-xl font-bold text-center'>Hola: {auth.firstName}</p>
            <div className="flex items-center gap-4 justify-center">
                <Link 
                    to="/dashboard"
                    className="font-bold uppercase">
                        DashBoard
                    </Link>
            </div>
            {/* <div className="flex items-center gap-4 justify-center">
                <button type="button" 
                    name="CerrarSesion"
                    className="text-with text-sm bg-sky-600 p-3 rounded-md uppercase fond-bold"
                    onClick={handleCerrarSesion}>
                    Cerrar Sesión
                </button>
            </div> */}
        </div>
    </header>
  )
}

export default Header;