import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AuthLayout from './layouts/AuthLayout';
import RutaProtegida from './layouts/RutaProtegida';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import ConfirmAccount from './pages/ConfirmAccount';
import PassRecovery from './pages/PassRecovery';
import PassReset from './pages/PassReset';
import Dashboard from './pages/Dashboard/Dashboard';
import ConstanciaNew from './pages/Dashboard/Constancias/ConstanciaNew'
import FormUsersList from './pages/Dashboard/Users/UsersList';
import { AuthProvider } from './context/AuthProvider'
import { DashboardProvider } from './context/DashboardProvider'

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <DashboardProvider>
          <Routes>
            <Route path='/' element={<AuthLayout />}>
              <Route index element={<Login />} />
              <Route path='singup' element={<SignUp />} />
              <Route path='confirmaccount/:token' element={<ConfirmAccount />} /> 
              <Route path='passrecovery' element={<PassRecovery />} />
              <Route path='passreset/:token' element={<PassReset />} />
            </Route>
            <Route path='/dashboard' element={<RutaProtegida />}>
              <Route index element={<Dashboard />} />
              <Route path='addconstancia' element={<ConstanciaNew />} />
              <Route path='usersList' element={<FormUsersList />} />
            </Route>
          </Routes>
        </DashboardProvider>
      </AuthProvider>
    </ BrowserRouter>  
  );
}

export default App;
