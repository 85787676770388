import { useState} from 'react';
import { Link } from 'react-router-dom';
import clientAxios from '../config/clientAxios';
import Alerts from '../components/Alerts';

const SingUp = () => {
const [ firstName, setFirstName] = useState('');
const [ lastName, setLastName] = useState('');
const [ email, setEmail] = useState('');
const [ password, setPassword] = useState('');
const [ repytPassword, setRepytPassword] = useState('');
const [ alert, setAlert ] = useState('');
  
  const handleSubmit = async e => {
    e.preventDefault();
    if (!email.includes('@aceromex.com')) {
        setAlert({
            msg: 'Correo invalido favor de verificar.',
            error: true
          });
          return
    }
    
    if ([firstName, lastName, email, password, repytPassword].includes('')) {
      setAlert({
        msg: 'Todos los campos son obligatorios.',
        error: true
      });
      return
    } 
    if (password !== repytPassword) {
      setAlert({
        msg: 'Los passwords no son iguales.',
        error: true
      });
      return
    } 
    if (password.length < 7) {
      setAlert({
        msg: 'El password debe ser de mas de 6 caracteres.',
        error: true
      });
      return
    } 
    setAlert({});
    //Crear el User en el API
    try {            
      const { data } = await clientAxios.post(`/users/login`, {firstName, lastName, email, password}); 
      setAlert({ msg: data.msgErr, error: false });
      setFirstName('');
      setLastName('');
      setEmail('');
      setPassword('');
      setRepytPassword('');
    } catch (error) {      
      setAlert({ msg:error.response.data.msgErr, error: true});
    }
  }

  const { msg } = alert;
    return (
        <>
            <h1 className="text-sky-600 font-black text-6xl capitalize">Registrate</h1>
            {msg && <Alerts alert={alert} />}
            <form 
                className="my-10 bg-white shadow rounded-lg p-10"
                onSubmit={handleSubmit}
            >
                <div className="my-5">
                    <label 
                        className="uppercase text-gray-600 block text-xl font-bold"
                        htmlFor="name"
                    >Nombre</label>
                    <input 
                        id="firstName"
                        type="text"
                        placeholder="Nombre del Usuario."
                        className="w-full mt-3 p-3 border rounded-xl bg-gray-50"
                        value={firstName}
                        onChange={e => setFirstName(e.target.value)}
                    />
                </div>
                <div className="my-5">
                    <label 
                        className="uppercase text-gray-600 block text-xl font-bold"
                        htmlFor="name"
                    >Apellidos</label>
                    <input 
                        id="lastName"
                        type="text"
                        placeholder="Apellidos del Usuario."
                        className="w-full mt-3 p-3 border rounded-xl bg-gray-50"
                        value={lastName}
                        onChange={e => setLastName(e.target.value)}
                    />
                </div>
                <div className="my-5">
                    <label 
                        className="uppercase text-gray-600 block text-xl font-bold"
                        htmlFor="email"
                    >Email</label>
                    <input 
                        id="email"
                        type="email"
                        placeholder="Email de Registro."
                        className="w-full mt-3 p-3 border rounded-xl bg-gray-50"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                </div>
                <div className="my-5">
                    <label 
                        className="uppercase text-gray-600 block text-xl font-bold"
                        htmlFor="password"
                    >Password</label>
                    <input 
                        id="password"
                        type="password"
                        placeholder="Password de acceso."
                        className="w-full mt-3 p-3 border rounded-xl bg-gray-50"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                </div>
                <div className="my-5">
                    <label 
                        className="uppercase text-gray-600 block text-xl font-bold"
                        htmlFor="password2"
                    >Repetir Password</label>
                    <input 
                        id="password2"
                        type="password"
                        placeholder="Repetir Password."
                        className="w-full mt-3 p-3 border rounded-xl bg-gray-50"
                        value={repytPassword}
                        onChange={e => setRepytPassword(e.target.value)}
                    />
                </div>
                <input
                    type="submit"
                    value="Crear Cuenta"
                    className="bg-sky-400 md-5 w-full py-3 text-white uppercase fond-bold rounded-xl hover:cursor-pointer hover:bg-sky-800 transition-colors"
                />
            </form>
            <nav className="lg:flex lg:justify-between">
            <Link
                className="block text-center my-5 text-slate-500 uppercase text-sm"
                to="/"
                >Login.</Link>
            <Link
                className="block text-center my-5 text-slate-500 uppercase text-sm"
                    to="/passrecovery"
                >Olvide mi password.</Link>
            </nav>
        </>
    )
}
export default SingUp