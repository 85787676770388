import { useState, useEffect, createContext } from 'react';
import clientAxios from '../config/clientAxios';
import {useNavigate} from 'react-router-dom';

const DashboardContext = createContext();

const DashboardProvider =({children}) => {
    const [constancias, setConstancias] = useState([]);
    const [alert, setAlert] = useState([]);
    const navigate = useNavigate();

     useEffect(() =>{
         const obteberConstancias = async() => {
             try {
                const token = localStorage.getItem('token');
                if (!token) return;
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: "Bearer " + token
                    }
                }

                const { data } = await clientAxios.get(`/dashboardUserList`, config);
                setConstancias(data);
                console.log(data);

             } catch (error) {
                 console.log(error);
             }
         };
         obteberConstancias();
     },[])

    const mostratAlert = alert =>{
        setAlert(alert);
        setTimeout(() =>{
            setAlert({})
        }, 5000);        
    }

    
    const cerrarSesionDashboard =() => {
        setConstancias([]);
    }

    return (
        <DashboardContext.Provider 
        value={{
            constancias,
            cerrarSesionDashboard
        }}
        >{children}
        </DashboardContext.Provider>
    )    
}

export{ DashboardProvider };

export default DashboardContext;