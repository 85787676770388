import { useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import React from 'react'
import Alerts from '../components/Alerts';
import clientAxios from '../config/clientAxios';
import useAuth from '../hooks/useAuth';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [alert, setAlert] =useState({});
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async e =>{
    e.preventDefault();
    if (!email.includes('@aceromex.com')) {
      setAlert({
          msg: 'Correo invalido favor de verificar.',
          error: true
        });
        return
    }
    if ([email, password].includes('')) {
      setAlert({ msg: 'Todos los campos son obligatorios', error: true });
      return
    }
    try {
      const { data } = await clientAxios.get(`/users/login/${email}/${password}`);
      setAlert({});
      if (data.codeErr === 0) {
        localStorage.setItem('token', data.token); 
        localStorage.setItem('email', data.email); 
        setAuth(data);
        navigate('/dashboard');
      }
    } catch (error) {
      console.log(error.response);
      setAlert({ msg:error.response.data.msgErr, error: true});
    }
  }
  const { msg } = alert;  
  return (
    <>    
      <h1 className="text-red-900 font-black text-6xl capitalize">Inicia sesion</h1>
      {msg && <Alerts alert={alert }/>}
      <form
        className="my-10 bg-white shadow rounded-lg p-10"
        onSubmit={handleSubmit}
      >
      <div className="my-5">
      <label className="uppercase text-gray-600 block text-xl font-bold" htmlFor="email">Email</label>
      <input 
            id="email" type="email" placeholder="Email de Registro." className="w-full mt-3 p-3 border rounded-xl bg-gray-50"
            value={email}
            onChange={ e => setEmail(e.target.value)}
          />
      </div>
      <div className="my-5">
        <label className="uppercase text-gray-600 block text-xl font-bold" htmlFor="password" >Password</label>
        <input 
            id="password" type="password"placeholder="Password de acceso." className="w-full mt-3 p-3 border rounded-xl bg-gray-50"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
      </div>
      <input
          type="submit" value="Iniciar Sesión" className="bg-red-400 md-5 w-full py-3 text-white uppercase fond-bold rounded-xl hover:cursor-pointer hover:bg-red-800 transition-colors"
        />
      </form>
      <nav className="lg:flex lg:justify-between">
      <Link
        className="block text-center my-5 text-slate-500 uppercase text-sm"
          to="/singup"
        >Registrarse.</Link>
        <Link
          className="block text-center my-5 text-slate-500 uppercase text-sm"
            to="/passrecovery"
          >Olvide mi password.</Link>
      </nav>
    </>
  )
}

export default Login