import { Link } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import useDashboard from '../hooks/useDashboard';

const Sidebar = () => {
  const { auth } = useAuth();
  const { cerrarSesionDashboard } = useDashboard();
    const { cerrarSesionAuth } = useAuth();
  const handleCerrarSesion = () => {
    cerrarSesionAuth();
    cerrarSesionDashboard();
    localStorage.removeItem('token');
  }
  return (
    <aside className="md:w-80 lg:w-96 px-5 py-10">
      {auth.components.CheckUsers && (
        <Link 
            name='users'
            to='usersList'
            className='bg-red-900 w-full p-3 text-white uppercase font-bold block mt-5 text-center rounded-lg'>
                Usuarios
        </Link>
      )}
      {auth.components.AddConstancia && (
        <Link 
            name='addconstancia'
            to='addconstancia'
            className='bg-red-900 w-full p-3 text-white uppercase font-bold block mt-5 text-center rounded-lg'>
                Subir Constancia
        </Link>
      )}
      <button type="button" 
              name="CerrarSesion"
              className="text-with text-sm bg-red-300 p-3 rounded-md uppercase fond-bold block mt-5 text-center rounded-lg"
              onClick={handleCerrarSesion}>
              Cerrar Sesión
      </button>
    </aside>
  )
}

export default Sidebar;