import {useState} from 'react';
import axios from "axios";
import Alerts from '../components/Alerts';

const FormConstanciaNew = () => {
    const [alert, setAlert] =useState({});
    const [file, setFile] = useState([]);

    const handleSubmit = async e => {
        e.preventDefault();

        if(document.getElementById("file").value !== "")
        {
            setAlert({ msg: 'Procesando', error: true });
            const files = new FormData();
            try{
                for (let index = 0; index < file.length; index++){
                    files.append('files', file[index]);
                }
                const email = localStorage.getItem('email');
                const config = {
                    headers:{
                        'Content-Type': 'multipart/form-data'
                    }};
                    const { data } = await axios.post(`https://api-aceromex.r347company.com/api/archivos/constancia?email=` + email, files, config);   
                try {
                    if (data.SATCSF.codeErr === '0' && data.SATCSF.msgErr === 'Successfully') {
                        setAlert({ msg: 'RFC:' + data.SATCSF.RFC + ' procesado', error: false });
                        setFile([]);
                        files.delete('files');
                        document.getElementById("file").value = "";
                      }
                      else if(data.SATCSF.codeErr === '0' && data.SATCSF.msgErr !== 'Successfully') 
                      {
                            try {
                                var MSGarray = '--';
                                data.SATCSF.STATUS.forEach(element => {
                                MSGarray =  MSGarray  + element + " --";
                              });
                            setAlert({ msg: MSGarray, error: true });
                            } catch (error) {
                                setAlert({ msg: data.SATCSF.STATUS, error: true });
                            }
                        setFile([]);
                        files.delete('files');
                        document.getElementById("file").value = "";
                      }
                      else
                      {
                        setAlert({ msg: data.SATCSF.msgErr, error: true });
                        setFile([]);
                        files.delete('files');
                        document.getElementById("file").value = "";
                      }
                } catch (error) {
                    if (data.codeErr === '0' && data.msgErr === 'Successfully') {
                        setAlert({ msg: 'RFC:' + data.rfc + ' procesado', error: false });
                        setFile([]);
                        files.delete('files');
                        document.getElementById("file").value = "";
                      }
                      else
                      {
                        setAlert({ msg: data.msgErr, error: true });
                        setFile([]);
                        files.delete('files');
                        document.getElementById("file").value = "";
                      }
                }
                
            
            }catch(err){
                console.log('error: ', err);
                //setAlert({ msg:error.response.data.msg, error: true});
            }
        }
    }

    const { msg } = alert; 

    return(
        <form className="my-10 bg-white shadow rounded-lg p-10" onSubmit={handleSubmit} >
                   
            <h1 className="text-4xl font-black">Agregar Constancia</h1>
            {msg && <Alerts alert={alert }/>}
            <div className="my-5">
                <input 
                    id='file' 
                    type='file' 
                    accept='application/pdf' 
                    name='files'
                    onChange={ e => setFile(e.target.files)} />
            </div>
            <input
            type="submit"
            value="Procesar"
            className="bg-sky-400 md-5 w-full py-3 text-white uppercase fond-bold rounded-xl hover:cursor-pointer hover:bg-sky-800 transition-colors"
            />
        
            
        </form>
    )
}

export default FormConstanciaNew;