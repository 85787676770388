import FormConstanciaNew from "../../../components/FormConstanciaNew";

const ConstanciaNew = () => {

    return (
        <>
            <FormConstanciaNew />             
        </>

    )
}

export default ConstanciaNew;